<template>

<com-content class="signup">

	<com-intro />
	<com-form />

</com-content>
	
</template>

<script>

export default {

	components: {

		'com-content': () => import('./common/Content'),
		'com-intro': () => import('./signup/Intro'),
		'com-form': () => import('./signup/Form')

	}

}

</script>

<style scoped>
</style>